import * as yup from "yup";
import React, { useContext, useState } from "react";
import { input, resetPassword } from "api/resetPassword";
import Form from "components/Form";
import FormInput from "components/FormInput";
import { Link } from "react-router-dom";
import { T } from "lib/language";
import { ThemeContext } from "templates/Application";
import styles from "./ResetPassword.module.scss";

export const formSchema = yup
  .object({
    password: yup.string().required(),
    password_confirm: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

function ResetPassword() {
  const themeName = useContext(ThemeContext);
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      <div className={styles.titleGroup}>
        <h1>
          <T id="resetPassword" />
        </h1>
      </div>
      {submitted ? (
        <>
          <p className={styles.success}>
            <T id="resetPassword.success" />
          </p>
          <Link className="ctaLink" to="/">
            <T id="back" />
          </Link>
        </>
      ) : (
        <Form
          schema={input}
          onSubmit={async (data) => {
            await resetPassword(data);
            setSubmitted(true);
          }}
        >
          <input
            type="hidden"
            name="app"
            value={themeName === "DJ" ? "dj" : "hh"}
          />
          <FormInput type="email" name="email">
            <T id="email" />
          </FormInput>
          <div className={styles.ctaGroup}>
            <button className="cta">
              <T id="submit" />
            </button>
            <Link className="ctaLink" to="/">
              <T id="back" />
            </Link>
          </div>
        </Form>
      )}
    </>
  );
}

export default ResetPassword;
