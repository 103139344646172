import {
  CalibrationGroupImg,
  CalibrationImg,
  CalibrationText,
} from "lib/calibrations";
import { FormattedDate, FormattedTime, useIntl } from "react-intl";
import { Link, useHistory, useParams } from "react-router-dom";
import Map, { MapToggle } from "./Map";
import {
  Measurement,
  MeasurementGroup,
  MeasurementsListItem,
  RangeValues,
  deleteGroupAttachment,
  deleteMeasurementGroup,
  getMeasurementGroup,
  getRangeValues,
  removeFromGroup,
  updateAttachmentSchema,
  updateCommentSchema,
  updateGroupAttachment,
  updateGroupComment,
  useMeasurementGroup,
} from "api/measurements";
import React, { useContext, useEffect, useRef, useState } from "react";
import { classes, formatPercentage, getTextTagByGroupType } from "lib/helpers";
import Checkbox from "components/Checkbox";
import Collapse from "components/Collapse";
import { ComprehensivenessIndicator } from "./ComprehensivenessIndicator";
import { ReactComponent as DeleteIcon } from "images/delete.svg";
import Form from "components/Form";
import GroupDetailsPrintableReport from "./GroupDetailsPrintableReport";
import { MeasurementGroupRefreshContext } from "../Context";
import Modal from "components/Modal";
import ReactToPrint from "react-to-print";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import TextArea from "components/TextArea";
import popupArrow from "images/popupArrow.svg";
import styles from "./GroupDetails.module.scss";
import stylesDetailsCommon from "./DetailsCommon.module.scss";
import useMessage from "lib/message";

function Basis({ basis }: { basis: number | null }) {
  function BasisText() {
    if (basis === null) {
      return <T id="measurement.wet" />;
    } else if (basis === 0) {
      return <T id="measurement.dry" />;
    } else {
      return <T id="measurement.fixed" values={{ basis }} />;
    }
  }
  return (
    <small>
      <BasisText />
    </small>
  );
}

function getAttachmentName(fullname: string) {
  let output = fullname.split("/").reverse()[0];
  output = output.substring(37, output.length); // Remove uuid
  output = output.split("?")[0]; // Remove possible query string

  return output;
}

function Attachment({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  let [counter, setState] = useState(0);
  const sendMessage = useMessage();

  return (
    <div className={stylesDetailsCommon.attachmentContainer}>
      {m.attachment ? (
        <div>
          <div className={stylesDetailsCommon.customTitle}>
            <T id="measurements.details.attachment" />
          </div>
          <a href={m.attachment} target="_blank" rel="noreferrer">
            {getAttachmentName(m.attachment)}
          </a>
          <DeleteIcon
            height={14}
            width={16}
            onClick={async () => {
              await deleteGroupAttachment(m.uuid)
                .then(function () {
                  m.attachment = "";
                  setState(counter + 1);
                })
                .then(
                  function () {
                    sendMessage(
                      <T id="measurements.details.deleteAttachment.success" />
                    );
                  },
                  function () {
                    sendMessage(
                      <T id="measurements.details.deleteAttachment.fail" />
                    );
                  }
                );
            }}
          />
        </div>
      ) : (
        <div>
          <div className={stylesDetailsCommon.customTitle}>
            <div className={stylesDetailsCommon.customTitle}>
              <T id="measurements.details.attachment" />
            </div>
          </div>
          -
        </div>
      )}

      <Form
        schema={updateAttachmentSchema}
        onSubmit={async (data) => {
          await updateGroupAttachment(m.uuid, data)
            .then((response) => {
              m.attachment = response.attachment;
              setState(counter + 1);
            })
            .then(
              function () {
                sendMessage(
                  <T id="measurements.details.updateAttachment.success" />
                );
              },
              function () {
                sendMessage(
                  <T id="measurements.details.updateAttachment.fail" />
                );
              }
            );
        }}
      >
        <label className="cta small">
          <input
            type="file"
            id="attachment"
            name="attachment"
            accept="image/png, image/jpeg"
          />
          <button type="submit" className="cta small">
            <T id="submit" />
          </button>
        </label>
      </Form>
    </div>
  );
}

function DetailsCollapse({
  measurementGroup: m,
  hovered: h,
}: {
  measurementGroup: MeasurementGroup;
  hovered: Measurement | MeasurementsListItem | null;
}) {
  const [open, setOpen] = useState(false);
  const { setDoRefresh } = useContext(MeasurementGroupRefreshContext);

  const onRemove = (uuidToRemove: string) => {
    if (setDoRefresh) {
      setDoRefresh(true);
    }
  };

  return (
    <>
      <button
        className={classes(
          "ctaLink medium",
          stylesDetailsCommon.detailsButton,
          open && styles.open
        )}
        onClick={() => setOpen(!open)}
      >
        <T id="measurements.details.details" />
        <img src={popupArrow} alt="Details" />
      </button>

      <Collapse open={open}>
        <MeasurementsListContainer
          onRemove={onRemove}
          hovered={h}
          measurementGroup={m}
        />
      </Collapse>
    </>
  );
}

function Input({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  const sendMessage = useMessage();
  return (
    <div className={stylesDetailsCommon.input}>
      <Form
        schema={updateCommentSchema}
        onSubmit={async (data) => {
          await updateGroupComment(m.uuid, data).then(
            function () {
              sendMessage(
                <T id="measurements.details.updateComment.success" />
              );
            },
            function () {
              sendMessage(<T id="measurements.details.updateComment.fail" />);
            }
          );
        }}
      >
        <TextArea
          name="comment"
          rows={3}
          styleSize="medium"
          defaultValue={m.comment}
        >
          <T id="measurement.comment" />
        </TextArea>
        <button type="submit" className="cta medium">
          <T id="measurements.details.updateComment" />
        </button>
      </Form>
    </div>
  );
}

function DetailsMap({
  measurementGroup,
  open,
  onClose,
  onHover,
}: {
  measurementGroup: MeasurementGroup;
  open: boolean;
  onClose: () => void;
  onHover: (item: Measurement | MeasurementsListItem | null) => void;
}) {
  const [resetToBounds, setResetToBounds] = useState(true);

  return (
    <div
      className={classes("mapBackground", open && "open")}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="map">
        <Map
          measurements={measurementGroup.measurements}
          resetToBounds={resetToBounds}
          setResetToBounds={setResetToBounds}
          onHovered={onHover}
        />
      </div>
    </div>
  );
}

function MeasurementsListContainer({
  measurementGroup: m,
  hovered: h,
  onRemove,
}: {
  measurementGroup: MeasurementGroup;
  hovered: Measurement | MeasurementsListItem | null;
  onRemove: (uuid: string) => void;
}) {
  const history = useHistory();
  const sendMessage = useMessage();
  const intl = useIntl();

  return (
    <div className={styles.measurementsContainer}>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <T id="measurement.date" />
            </th>
            <th>
              <T id="measurement.protein" />
            </th>
            <th>
              <T id="measurement.moisture" />
            </th>
            <th>
              <T id="measurement.oil" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {m.measurements.length === 0 ? (
            <tr>
              <td colSpan={5}></td>
              <td>-</td>
            </tr>
          ) : (
            m.measurements.map((item: Measurement) => (
              <tr
                key={item.uuid}
                onClick={(event) => {
                  const target = (event.target as HTMLElement).closest(
                    "td"
                  ) as HTMLTableCellElement;
                  if (target) {
                    const parentRow =
                      target.parentElement as HTMLTableRowElement;
                    // last cell is for removing the group
                    if (target.cellIndex === parentRow.cells.length - 1) {
                      event.stopPropagation();
                    } else {
                      history.push(`/measurements/${item.uuid}`);
                    }
                  }
                }}
                className={classes(item === h && styles.hovered)}
              >
                <td>
                  <div className={styles.species}>
                    <CalibrationImg calibration={item.calibration} />
                    <span className={styles.speciesText}>
                      <CalibrationText calibration={item.calibration} />
                    </span>
                  </div>
                </td>
                <td>
                  <FormattedDate value={item.measured_at} />
                  <br />
                  <FormattedTime
                    timeStyle={"medium"}
                    value={item.measured_at}
                  />
                </td>
                <td>{formatPercentage(item.protein)}</td>
                <td>{formatPercentage(item.moisture)}</td>
                <td>{formatPercentage(item.oil)}</td>
                <td
                  title={String(
                    intl.messages["measurementGroup.removeFromGroup.title"]
                  )}
                  onClick={(e) => {
                    removeFromGroup(item.uuid, {
                      measurement_group: null,
                    }).then(
                      function () {
                        onRemove(item.uuid);
                        sendMessage(
                          <T id="measurementGroup.removeFromGroup.success" />
                        );
                      },
                      function () {
                        sendMessage(
                          <T id="measurementGroup.removeFromGroup.fail" />
                        );
                      }
                    );
                  }}
                >
                  x
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}

function RangeText({
  rangeValues: values,
  compound,
}: {
  rangeValues: RangeValues | null;
  compound: string;
}) {
  if (values === null) return null;

  const minMax = [];

  switch (compound) {
    case "protein":
      minMax[0] = values.protein.min;
      minMax[1] = values.protein.max;
      break;
    case "carbohydrate":
      minMax[0] = values.carbohydrate.min;
      minMax[1] = values.carbohydrate.max;
      break;
    case "moisture":
      minMax[0] = values.moisture.min;
      minMax[1] = values.moisture.max;
      break;
    case "oil":
      minMax[0] = values.oil.min;
      minMax[1] = values.oil.max;
      break;
  }

  return (
    <small>
      <T id="measurement.range" /> {formatPercentage(minMax[0], "")} -{" "}
      {formatPercentage(minMax[1])}
    </small>
  );
}

function DetailsLoaded({
  measurementGroup: m,
  hovered: h,
}: {
  measurementGroup: MeasurementGroup;
  hovered: Measurement | MeasurementsListItem | null;
}) {
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [deleteMeasurementsChecked, setDeleteMeasurements] = useState(false);
  const hist = useHistory();
  const sendMessage = useMessage();
  let reportRef: any = useRef();

  return (
    <div className={stylesDetailsCommon.details}>
      <div className={styles.title}>
        <CalibrationGroupImg calibration={m.calibration} />
        <h3>
          <CalibrationText calibration={m.calibration} />
        </h3>
        <div className={styles.paddingLeft}>
          <ComprehensivenessIndicator comprehensiveness={m.comprehensiveness} />
        </div>
        <div className={stylesDetailsCommon.info}>
          <FormattedDate value={m.measured_at} />
          <br />
          <FormattedTime timeStyle={"medium"} value={m.measured_at} />
        </div>
        <div className={stylesDetailsCommon.info}>
          {m.measurements.length > 0 ? (
            <>
              <T id="measurements.details.gsAnalyzerSN" />:
              <br />
              {m.measurements[0].device}
            </>
          ) : null}
        </div>
        {m.group_type ? (
          <div className={styles.groupType}>
            <T id="measurementGroupType" />:
            <br />
            <T id={getTextTagByGroupType({ groupType: m.group_type })} />
          </div>
        ) : null}
        <div className={styles.printAndDeleteContainer}>
          <div className={styles.printButtonContainer}>
            <button type="button" className="cta medium">
              <ReactToPrint
                trigger={() => (
                  <span className={styles.printLink}>
                    <T id="measurement.details.printLink" />
                  </span>
                )}
                content={() => reportRef}
              />
              <div style={{ display: "none" }}>
                <div ref={(el) => (reportRef = el)}>
                  <GroupDetailsPrintableReport />
                </div>
              </div>
            </button>
          </div>
          <div className={styles.deleteIconContainer}>
            <DeleteIcon onClick={() => setDeleteOpened(true)} />
          </div>
        </div>
      </div>
      <div className={stylesDetailsCommon.body}>
        <div className={stylesDetailsCommon.data}>
          <Compounds measurementGroup={m} />
          <DetailsCollapse hovered={h} measurementGroup={m} />
        </div>
        <div>
          <div>
            <Input measurementGroup={m} />
          </div>
          <div>
            <Attachment measurementGroup={m} />
          </div>
        </div>
      </div>

      {deleteOpened ? (
        <Modal
          title={<T id="measurements.details.deleteGroup" />}
          onClose={() => setDeleteOpened(false)}
          buttons={
            <>
              <button
                className="cta medium danger"
                onClick={async () => {
                  let input = {
                    uuids: [m.uuid],
                    delete_measurements: deleteMeasurementsChecked,
                  };
                  await deleteMeasurementGroup(input).then(
                    function () {
                      setDeleteOpened(false);
                      hist.push("/measurements");
                    },
                    function () {
                      sendMessage(<T id="delete.fail" />);
                      setDeleteOpened(false);
                    }
                  );
                }}
              >
                <T id="delete" />
                <br />
              </button>

              {m.measurements.length > 0 ? (
                <div>
                  <Checkbox
                    checked={deleteMeasurementsChecked}
                    onClick={() =>
                      setDeleteMeasurements(!deleteMeasurementsChecked)
                    }
                  >
                    <T id="measurements.details.deleteAlsoMeasurements" />
                  </Checkbox>
                </div>
              ) : null}
            </>
          }
        >
          <T id="measurements.details.deleteGroup.warning" />
        </Modal>
      ) : null}
    </div>
  );
}

function Compounds({
  measurementGroup: m,
}: {
  measurementGroup: MeasurementGroup;
}) {
  const rangeValues = getRangeValues(m);

  return (
    <ul>
      <li>
        <div className={stylesDetailsCommon.iconProtein} />
        <div>
          <T id="measurement.protein" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].protein_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.protein)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"protein"} />
          ) : null}
        </div>
      </li>
      <li>
        <div className={stylesDetailsCommon.iconMoisture} />
        <div>
          <T id="measurement.moisture" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].moisture_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.moisture)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"moisture"} />
          ) : null}
        </div>
      </li>
      <li>
        <div className={stylesDetailsCommon.iconCarbohydrate} />
        <div>
          <T id="measurement.carbohydrate" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].carbohydrate_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.carbohydrate)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"carbohydrate"} />
          ) : null}
        </div>
      </li>
      <li>
        <div className={stylesDetailsCommon.iconOil} />
        <div>
          <T id="measurement.oil" />
          {m.measurements.length > 0 ? (
            <Basis basis={m.measurements[0].oil_fixed_percentage} />
          ) : null}
        </div>
        <div className={stylesDetailsCommon.percentageContainer}>
          <strong>{formatPercentage(m.oil)}</strong>
          {m.measurements.length > 1 ? (
            <RangeText rangeValues={rangeValues} compound={"oil"} />
          ) : null}
        </div>
      </li>
    </ul>
  );
}

function GroupDetails() {
  const { uuid } = useParams<{ uuid: string }>();
  const [measurementGroupData, , errors] = useMeasurementGroup(uuid);
  const [measurementGroup, setMeasurementGroup] = useState<
    MeasurementGroup | undefined
  >(undefined);
  const [doRefresh, setDoRefresh] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      getMeasurementGroup(uuid).then(
        (response) => {
          setMeasurementGroup(response);
        },
        () => {
          console.log("Fetching measurement group failed");
        }
      );
    };

    if (doRefresh) {
      fetchData();
      setDoRefresh(false);
    }
  }, [doRefresh, uuid]);

  useEffect(() => {
    if (measurementGroupData) {
      setMeasurementGroup(measurementGroupData);
    }
  }, [measurementGroupData]);

  const [mapOpen, setMapOpen] = useState(false);
  const [hovered, setHovered] = useState<
    Measurement | MeasurementsListItem | null
  >(null);

  if (!measurementGroup && errors.length === 0) {
    return <Spinner />;
  } else {
    if (measurementGroup === undefined) {
      return (
        <div className={stylesDetailsCommon.errormessage}>
          <b>Server responded: </b>
          <code>{errors.length > 0 ? errors[0] : ""}</code>
        </div>
      );
    } else {
      return (
        <MeasurementGroupRefreshContext.Provider
          value={{
            setDoRefresh: setDoRefresh,
          }}
        >
          <h1>{measurementGroup.groupName}</h1>
          <div className={classes("dashboardTop", stylesDetailsCommon.top)}>
            <Link to="/measurements" className="ctaLink">
              <T id="back" />
            </Link>
            <MapToggle open={mapOpen} onOpen={setMapOpen} />
          </div>
          <div className={stylesDetailsCommon.root}>
            <div className={stylesDetailsCommon.detailsContainer}>
              <DetailsLoaded
                hovered={hovered}
                measurementGroup={measurementGroup}
              />
            </div>
            {measurementGroup.measurements.length > 0 ? (
              <DetailsMap
                measurementGroup={measurementGroup}
                open={mapOpen}
                onClose={() => setMapOpen(false)}
                onHover={setHovered}
              />
            ) : null}
          </div>
        </MeasurementGroupRefreshContext.Provider>
      );
    }
  }
}

export default GroupDetails;
