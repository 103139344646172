import * as yup from "yup";
import { api } from "lib/api";

export const input = yup
  .object({
    email: yup.string().email().required(),
    app: yup.string().required(),
  })
  .required();

export const resetPassword = api("POST", "v1/account/password/reset/")
  .inputSchema(input)
  .call();

export const confirmInput = yup
  .object({
    token: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

export const resetPasswordConfirm = api(
  "POST",
  "v1/account/password/reset/confirm/"
)
  .inputSchema(confirmInput)
  .call();
