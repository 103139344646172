import * as Sentry from "@sentry/react";
import * as yup from "yup";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React, { createContext, useState } from "react";
import Dashboard from "./Dashboard";
import LoggedOut from "./LoggedOut";
import { useSession } from "lib/session";

export const ThemeContext = createContext<string | undefined>(undefined);

type Colors = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  color6: string;
  color7: string;
  color8: string;
  color9: string;
  color10: string;
  color11: string;
  color12: string;
  color13: string;
  color14: string;
  color15: string;
  color16: string;
  color17: string;
  color18: string;
  color19: string;
  color20: string;
  color21: string;
};
type Theme = {
  name: string;
  colors: Colors;
};

const changeWindowTitle = (themeName: string): void => {
  if (themeName !== "GS") {
    document.title = "Dashboard";
  }
};

const addTheme = (
  themeString: string | undefined,
  setTheme: React.Dispatch<React.SetStateAction<Theme | undefined>>
) => {
  const root = document.documentElement;
  const hexColorRegex =
    /^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const invalidColorMessage = "Invalid hex color";
  const colorsSchema = yup.object().shape({
    color1: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color2: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color3: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color4: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color5: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color6: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color7: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color8: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color9: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color10: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color11: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color12: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color13: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color14: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color15: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color16: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color17: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color18: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color19: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color20: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
    color21: yup.string().matches(hexColorRegex, invalidColorMessage).defined(),
  });
  const themeSchema = yup.object().shape({
    name: yup.string().defined(),
    colors: colorsSchema.required().defined(),
  });

  if (themeString) {
    try {
      let theme: Theme = JSON.parse(themeString);

      themeSchema
        .validate(theme)
        .then((validatedData: Theme) => {
          setTheme(validatedData);
          changeWindowTitle(validatedData.name);
          for (const color in validatedData.colors) {
            if (validatedData.colors.hasOwnProperty(color)) {
              root.style.setProperty(
                `--${color}`,
                validatedData.colors[color as keyof Colors]
              );
            }
          }
        })
        .catch((err) => {
          console.error("Validation error:", err.errors);
        });
    } catch (e) {
      console.error("Invalid JSON:", e);
    }
  } else {
    console.log("Missing REACT_APP_THEME");
  }
};

function Application() {
  const { token } = useSession();
  const [theme, setTheme] = useState<Theme | undefined>(undefined);
  const themeString = process.env.REACT_APP_THEME;
  if (!theme && themeString) {
    addTheme(themeString, setTheme);
  }

  return (
    <ThemeContext.Provider value={theme ? theme.name : "GS"}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/verify"
            render={(props) => {
              return (
                <Redirect
                  to={{
                    pathname: "/login/verify",
                    search: props.location.search,
                  }}
                />
              );
            }}
          />
          <Route
            exact
            path="/reset_password/confirm"
            render={(props) => {
              return (
                <Redirect
                  to={{
                    pathname: "/login/reset-password/confirm",
                    search: props.location.search,
                  }}
                />
              );
            }}
          />
          <Route path="/login">
            {token ? <Redirect to="/measurements" /> : <LoggedOut />}
          </Route>
          <Route>{token ? <Dashboard /> : <Redirect to="/login" />}</Route>
        </Switch>
      </BrowserRouter>
    </ThemeContext.Provider>
  );
}

export default Sentry.withProfiler(Application);
