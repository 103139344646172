import { useLocation } from "react-router";
import { useMemo } from "react";

export function classes(...cs: any[]) {
  return cs.filter((c) => Boolean(c)).join(" ");
}

export function formatError(error: string) {
  const capitalized = error.charAt(0).toUpperCase() + error.substring(1);
  if (capitalized.substring(capitalized.length - 1) === ".") {
    return capitalized;
  }
  return capitalized + ".";
}

export function formatErrors(errors: string[] = []) {
  return errors.map(formatError).join(" ");
}

export function formatPercentage(n: number | null, suffix: string = "%") {
  let output = null;

  if (n !== null) {
    output = (n * 100).toFixed(1) + suffix;
  }

  return output;
}

export function formatGluten(n: number, suffix: string = "%") {
  return (Math.round(n * 200) / 2).toFixed(1) + suffix;
}

export function setCookie(name: string, value: string, maxAge?: number) {
  const maxAgeString = maxAge === undefined ? "" : "; max-age" + maxAge;
  document.cookie =
    name + "=" + value + maxAgeString + "; path=/; samesite=strict";
}

export function getCookie(name: string) {
  const nameEQ = name + "=";
  const cookie = document.cookie.split("; ").find((c) => c.startsWith(nameEQ));
  if (cookie) {
    return cookie.substring(nameEQ.length);
  } else {
    return null;
  }
}

export function clearCookie(name: string) {
  document.cookie = name + "=; max-age=0; path=/; samesite=strict";
}

export function useQueryParams() {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

type GroupTypeParams = { groupType: string | undefined };

export function getTextTagByGroupType(params: GroupTypeParams): string {
  let output;

  const { groupType } = params;

  if (groupType) {
    switch (groupType) {
      case "Grain cart":
        output = "measurementGroupType.grainCart";
        break;
      case "IBA":
        output = "measurementGroupType.iba";
        break;
      case "Field":
        output = "measurementGroupType.field";
        break;
      case "Silo/Storage":
        output = "measurementGroupType.siloStorage";
        break;
      case "Other":
        output = "measurementGroupType.other";
        break;
      default:
        output = "measurementGroupType.unknown";
    }
  } else {
    output = "measurementGroupType.unknown";
  }

  return output;
}
