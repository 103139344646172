import { ReactComponent as Account } from "images/sidebar/account.svg";
import { ReactComponent as IBA } from "images/sidebar/iba.svg";
import { ReactComponent as Info } from "images/sidebar/info.svg";
import { ReactComponent as Measurements } from "images/sidebar/measurements.svg";
import { NavLink } from "react-router-dom";
import { ReactComponent as ReleaseNotes } from "images/sidebar/releaseNotes.svg";
import { ReactComponent as Support } from "images/sidebar/support.svg";
import { T } from "lib/language";
import { ThemeContext } from "./Application";
import { ReactComponent as Webshop } from "images/sidebar/webshop.svg";
import logo from "images/logo.svg";
import logoDJ from "images/DJ/dj-LogoWhite.png";
import styles from "./Sidebar.module.scss";
import { useContext } from "react";
import { useSession } from "lib/session";

function Sidebar() {
  const { name, logOut } = useSession();
  const themeName = useContext(ThemeContext);

  return (
    <nav className={styles.root}>
      <img
        src={themeName === "DJ" ? logoDJ : logo}
        className={styles.logo}
        alt="Logo"
      />
      <div className={styles.sectionMain}>
        <T id="sidebar.main" />
      </div>
      <NavLink to="/measurements" activeClassName={styles.active}>
        <Measurements />
        <T id="measurements" />
      </NavLink>
      <NavLink to="/iba" activeClassName={styles.active}>
        <IBA />
        <T id="iba" />
      </NavLink>
      <NavLink to="/account" activeClassName={styles.active}>
        <Account />
        <T id="account" />
      </NavLink>
      {themeName === "GS" ? (
        <NavLink to="/webshop" activeClassName={styles.active}>
          <Webshop />
          <T id="webshop" />
        </NavLink>
      ) : null}
      <div className={styles.sectionHelp}>
        <T id="sidebar.help" />
      </div>
      <NavLink to="/info" activeClassName={styles.active}>
        <Info />
        <T id="info" />
      </NavLink>
      <NavLink to="/release-notes" activeClassName={styles.active}>
        <ReleaseNotes />
        <T id="releaseNotes" />
      </NavLink>
      <a href="mailto:support@grainsense.com">
        <Support />
        <T id="support" />
      </a>
      <div className={styles.userSection}>
        <div className={styles.userName}>{name}</div>
        <button onClick={logOut}>
          <T id="logOut" />
          {" >"}
        </button>
      </div>
    </nav>
  );
}

export default Sidebar;
