import {
  MeasurementsListItem,
  addMeasurementsIntoGroup,
  deleteMeasurementGroup,
  deleteMeasurements,
  exportMeasurements,
  makeGroupApiCall,
  measurementGroupTypeOptions,
} from "api/measurements";
import React, { useMemo, useState } from "react";
import { calibrations, getCalibrationTextId } from "lib/calibrations";
import { formatGluten, formatPercentage } from "lib/helpers";
import { ReactComponent as ActionsIcon } from "images/actions.svg";
import Checkbox from "components/Checkbox";
import Dropdown from "components/popup/Dropdown";
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { T } from "lib/language";
import errorImage from "images/error.svg";
import styles from "./ListActions.module.scss";
import { useIntl } from "react-intl";
import useMessage from "lib/message";

const options = ["average", "export", "delete", "grouping"] as const;
type Option = (typeof options)[number];

type ExportFormat = "xlsx" | "csv";

function ListActions({
  measurements,
  onDelete,
  onGroup,
}: {
  measurements: MeasurementsListItem[];
  onDelete: () => void;
  onGroup: () => void;
}) {
  const [selected, setSelected] = useState<Option>();
  const [averageErroMsgKey, setAverageErrorMsgKey] = useState<string>();
  const [groupingErrorMsgKey, setGroupingErrorMsgKey] = useState<string>();
  const [deleteMeasurementsChecked, setDeleteMeasurements] = useState(false);
  const onClose = () => setSelected(undefined);

  function Average() {
    const empty = {
      protein: 0,
      moisture: 0,
      carbohydrate: 0,
      oil: 0,
      total: 0,
      gluten_iso: 0 as number | null,
      gluten_gost: 0 as number | null,
      nitrogen: 0 as number | null,
    };

    const sum = measurements.reduce<typeof empty>(
      (sum, m) => ({
        protein: sum.protein + (m.protein === null ? 0 : m.protein),
        moisture: sum.moisture + (m.moisture === null ? 0 : m.moisture),
        carbohydrate:
          sum.carbohydrate + (m.carbohydrate === null ? 0 : m.carbohydrate),
        oil: sum.oil + (m.oil === null ? 0 : m.oil),
        total: sum.total + m.total,
        gluten_iso:
          m.gluten_iso !== null && sum.gluten_iso !== null
            ? sum.gluten_iso + m.gluten_iso
            : null,
        gluten_gost:
          m.gluten_gost !== null && sum.gluten_gost !== null
            ? sum.gluten_gost + m.gluten_gost
            : null,
        nitrogen:
          m.nitrogen !== null && sum.nitrogen !== null
            ? sum.nitrogen + m.nitrogen
            : null,
      }),
      empty
    );

    const count = measurements.length;

    const protein = sum.protein / count;
    const moisture = sum.moisture / count;
    const carbohydrate = sum.carbohydrate / count;
    const oil = sum.oil / count;
    const total = sum.total / count;
    const gluten_iso = sum.gluten_iso !== null ? sum.gluten_iso / count : null;
    const gluten_gost =
      sum.gluten_gost !== null ? sum.gluten_gost / count : null;
    const nitrogen = sum.nitrogen !== null ? sum.nitrogen / count : null;

    return (
      <Modal
        title={<T id="measurements.actions.average.title" />}
        onClose={onClose}
      >
        <ul className={styles.averages}>
          <li>
            <T id="measurement.protein" />
            <span>{formatPercentage(protein)}</span>
          </li>
          <li>
            <T id="measurement.moisture" />
            <span>{formatPercentage(moisture)}</span>
          </li>
          <li>
            <T id="measurement.carbohydrate" />
            <span>{formatPercentage(carbohydrate)}</span>
          </li>
          <li>
            <T id="measurement.oil" />
            <span>{formatPercentage(oil)}</span>
          </li>
          <li>
            <T id="measurement.sampleMass" />
            <span>{total.toFixed(0)} mg</span>
          </li>
          {gluten_iso !== null ? (
            <li>
              <T id="measurement.wetGluten" />{" "}
              <T id="measurement.wetGluten.iso" />
              <span>{formatGluten(gluten_iso)}</span>
            </li>
          ) : null}
          {gluten_gost !== null ? (
            <li>
              <T id="measurement.wetGluten" />{" "}
              <T id="measurement.wetGluten.gost" />
              <span>{formatGluten(gluten_gost)}</span>
            </li>
          ) : null}
          {nitrogen !== null ? (
            <li>
              <T id="measurement.nitrogen" />
              <span>{formatPercentage(nitrogen, " N%")}</span>
            </li>
          ) : null}
        </ul>
      </Modal>
    );
  }

  function Export() {
    const [format, setFormat] = useState<ExportFormat>("xlsx");
    const [sendEmail, setSendEmail] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    return (
      <Modal
        title={<T id="measurements.actions.export" />}
        onClose={onClose}
        buttons={
          <button
            className="cta medium"
            disabled={downloadButtonDisabled}
            form="exportForm"
          >
            <T id="download" />
          </button>
        }
      >
        <form
          id="exportForm"
          className={styles.export}
          noValidate
          onSubmit={async (e) => {
            e.preventDefault();

            setDownloadButtonDisabled(true);
            setShowSpinner(true);

            let allMeasurementUUIDs: string[] = measurements
              .filter((m) => !m.isGroup)
              .map((m) => m.uuid);

            measurements
              .filter((m) => m.isGroup)
              .forEach(function (m) {
                m.measurements?.forEach((item) =>
                  allMeasurementUUIDs.push(item)
                );
              });

            exportMeasurements({
              measurements: allMeasurementUUIDs,
              send_email: sendEmail,
              format,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
              .then(() => {
                onClose();
              })
              .catch((e) => {
                setErrorMessage(e.toString());
              })
              .finally(() => {
                setShowSpinner(false);
                setDownloadButtonDisabled(false);
              });
          }}
        >
          <T id="measurements.actions.export.format.description" />:
          <fieldset>
            <label>
              <input
                type="radio"
                checked={format === "xlsx"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormat("xlsx");
                  }
                }}
              />
              <T id="measurements.actions.export.format.excel" />
            </label>
            <label>
              <input
                type="radio"
                checked={format === "csv"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setFormat("csv");
                  }
                }}
              />
              <T id="measurements.actions.export.format.csv" />
            </label>
          </fieldset>
          <T id="measurements.actions.export.download.description" />
          <fieldset>
            <label>
              <input
                type="radio"
                checked={!sendEmail}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSendEmail(false);
                  }
                }}
              />
              <T id="measurements.actions.export.download.directDownload" />
            </label>
            <label>
              <input
                type="radio"
                checked={sendEmail}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSendEmail(true);
                  }
                }}
              />
              <T id="measurements.actions.export.download.email" />
            </label>
          </fieldset>
          {errorMessage !== "" ? (
            <p>
              <code>{errorMessage}</code>
            </p>
          ) : null}
          {showSpinner ? <Spinner small={true} /> : null}
        </form>
      </Modal>
    );
  }

  function CreateNewGroup({ orphans }: { orphans: MeasurementsListItem[] }) {
    let defaultCalibration: string = calibrations[0];

    if (orphans.length > 0) {
      defaultCalibration = orphans[0].calibration;
    }

    const intl = useIntl();
    const [groupName, setGroupName] = useState("");
    const [comment, setComment] = useState("");
    const [calibration, setCalibration] = useState<string>(defaultCalibration);
    const [minMeasurements, setMinMeasurements] = useState(5);
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const [measurementGroupType, setMeasurementGroupType] = useState(
      measurementGroupTypeOptions[0] as string
    );

    const createGroup = async () => {
      let input = {
        name: groupName,
        comment: comment,
        measurement_group_type: measurementGroupType,
        required_number_of_measurements: minMeasurements,
        calibration: calibration,
      };

      makeGroupApiCall(input)
        .then(
          (data) => {
            if (orphans.length > 0) {
              addMeasurementsIntoGroup({
                measurement_group_uuid: data.uuid,
                measurement_uuids: orphans.map((item) => item.uuid),
              });
            }
          },
          () => {
            console.log("fail, show error message");
          }
        )
        .finally(() => {
          onClose();
          onGroup();
        });
    };

    function getMeasurementGroupTypeTextId(type: string) {
      if (type === measurementGroupTypeOptions[0]) {
        return "measurementGroupType.field";
      } else if (type === measurementGroupTypeOptions[1]) {
        return "measurementGroupType.grainCart";
      } else if (type === measurementGroupTypeOptions[2]) {
        return "measurementGroupType.iba";
      } else if (type === measurementGroupTypeOptions[3]) {
        return "measurementGroupType.siloStorage";
      } else if (type === measurementGroupTypeOptions[4]) {
        return "measurementGroupType.other";
      } else {
        return "calibration.unknown";
      }
    }

    return (
      <Modal
        title={<T id="measurements.actions.createGroup" />}
        onClose={onClose}
        buttons={
          <button
            className="cta medium"
            disabled={createButtonDisabled}
            onClick={createGroup}
          >
            <T id="measurements.actions.createGroup" />
          </button>
        }
      >
        <div className={styles.createGroupDialog}>
          <FormInput
            name="name"
            styleSize={"medium"}
            onChangeValue={setGroupName}
            value={groupName}
          >
            <T id="measurement.groupName" />
          </FormInput>

          <FormInput
            name="comment"
            styleSize={"medium"}
            onChangeValue={setComment}
            value={comment}
          >
            <T id="measurement.comment" />
          </FormInput>

          <FormSelect
            styleSize={"medium"}
            options={
              orphans.length === 0
                ? calibrations
                : calibrations.filter((item) => item === orphans[0].calibration)
            }
            value={calibration}
            onValue={setCalibration}
            render={(c) => intl.formatMessage({ id: getCalibrationTextId(c) })}
          >
            <T id="measurement.species" />
          </FormSelect>

          <FormSelect
            styleSize={"medium"}
            options={measurementGroupTypeOptions}
            value={measurementGroupType}
            onValue={setMeasurementGroupType}
            render={(c) =>
              intl.formatMessage({ id: getMeasurementGroupTypeTextId(c) })
            }
          >
            <T id="measurementGroupType" />
          </FormSelect>

          <div className={styles.requiredNumber}>
            <T id="measurementGroup.newGroup.dialog.requiredNumber" />
          </div>

          <input
            className={styles.requiredNumberInput}
            type="number"
            required
            min={5}
            max={200}
            step={1}
            value={minMeasurements || 5}
            onChange={(e) => {
              if (e.target.value === "") {
                setMinMeasurements(5);
              }
              const value = e.target.valueAsNumber;
              if (!isNaN(value)) {
                setMinMeasurements(value);
              }
            }}
          />
        </div>
      </Modal>
    );
  }

  function ConfirmAddIntoGroup({
    group_uuid,
    orphans,
  }: {
    group_uuid: string;
    orphans: MeasurementsListItem[];
  }) {
    const [addButtonPressed, setAddButtonPressed] = useState(false);
    const [messageTranslationId, setMessageTranslationId] = useState("");

    const addIntoGroup = () => {
      addMeasurementsIntoGroup({
        measurement_group_uuid: group_uuid,
        measurement_uuids: orphans.map((item) => item.uuid),
      })
        .then(
          () => {
            setMessageTranslationId(
              "measurementGroup.addIntoGroup.message.success"
            );

            setSelected(undefined);
          },
          () =>
            setMessageTranslationId(
              "measurementGroup.addIntoGroup.message.fail"
            )
        )
        .finally(() => {
          setAddButtonPressed(true);
          onGroup();
        });
    };

    if (addButtonPressed && messageTranslationId.length > 0) {
      return (
        <Modal onClose={onClose} title={""}>
          <T id={messageTranslationId} />
        </Modal>
      );
    } else {
      return (
        <Modal
          onClose={onClose}
          title={<T id="measurementGroup.addIntoGroup.dialog.title" />}
          buttons={
            <button className="cta medium" onClick={addIntoGroup}>
              <T id="confirm" />
            </button>
          }
        >
          <T id={"measurementGroup.newGroup.dialog.text"} />
        </Modal>
      );
    }
  }

  function Grouping() {
    const groups = measurements.filter((obj) => obj.isGroup === true);
    const orphans = measurements.filter((obj) => obj.isGroup === false);
    let output = null;

    if (groups.length === 0) {
      output = <CreateNewGroup orphans={orphans} />;
    } else if (groups.length === 1) {
      output = (
        <ConfirmAddIntoGroup group_uuid={groups[0].uuid} orphans={orphans} />
      );
    } else {
      // no support for joining groups yet
    }

    return output;
  }

  function Delete() {
    const sendMessage = useMessage();

    if (measurements[0].isGroup) {
      let groupsWithMeasurementsCount = measurements.filter(
        (m) => m.protein !== null
      ).length;

      return (
        <Modal
          title={<T id="measurements.actions.deleteGroups.title" />}
          onClose={onClose}
          buttons={
            <>
              <button
                className="cta medium danger"
                onClick={async () => {
                  let input = {
                    uuids: measurements.map((m) => m.uuid),
                    delete_measurements: deleteMeasurementsChecked,
                  };

                  await deleteMeasurementGroup(input)
                    .then(null, () => sendMessage(<T id="delete.fail" />))
                    .finally(() => {
                      onClose();
                      onDelete();
                    });
                }}
              >
                <T id="delete" />
              </button>
              {groupsWithMeasurementsCount > 0 ? (
                <div>
                  <Checkbox
                    checked={deleteMeasurementsChecked}
                    onClick={() =>
                      setDeleteMeasurements(!deleteMeasurementsChecked)
                    }
                  >
                    <T id="measurements.details.deleteAlsoMeasurements" />
                  </Checkbox>
                </div>
              ) : null}
            </>
          }
        >
          <T
            id="measurements.actions.deleteGroups.warning"
            values={{ number: measurements.length }}
          />
        </Modal>
      );
    } else {
      return (
        <Modal
          title={<T id="measurements.actions.delete.title" />}
          onClose={onClose}
          buttons={
            <button
              className="cta medium danger"
              onClick={async () => {
                await deleteMeasurements(measurements.map((m) => m.uuid))
                  .then(null, () => sendMessage(<T id="delete.fail" />))
                  .finally(() => {
                    onClose();
                    onDelete();
                  });
              }}
            >
              <T id="delete" />
            </button>
          }
        >
          <T
            id="measurements.actions.delete.warning"
            values={{ number: measurements.length }}
          />
        </Modal>
      );
    }
  }

  const groupingDisabled = useMemo(() => {
    let output = false;
    setGroupingErrorMsgKey(undefined);

    let groups = measurements.filter((obj) => obj.isGroup === true);
    let orphans = measurements.filter((obj) => obj.isGroup === false);

    if (groups.length < 2 && orphans.length > 0) {
      output = false;

      if (groups.length === 1) {
        let groupCalibration = groups[0].calibration;

        if (orphans.length === 0) {
          output = true;
          setGroupingErrorMsgKey(
            "measurements.actions.groupingError.selectMeasurements"
          );
        } else {
          if (orphans.every((m) => m.calibration === groupCalibration)) {
            output = false;

            if (groups[0].device) {
              if (!orphans.every((m) => m.device === groups[0].device)) {
                setGroupingErrorMsgKey(
                  "measurements.actions.groupingError.needSameDevice"
                );
                output = true;
              }
            } else {
              if (!orphans.every((m) => m.device === orphans[0].device)) {
                setGroupingErrorMsgKey(
                  "measurements.actions.groupingError.needSameDevice"
                );
                output = true;
              }
            }
          } else {
            setGroupingErrorMsgKey(
              "measurements.actions.groupingError.needSameCalibration"
            );
            output = true;
          }
        }
      } else {
        if (orphans.length > 1) {
          let calibration = orphans[0].calibration;

          if (orphans.every((m) => m.calibration === calibration)) {
            output = false;
            let device = orphans[0].device;

            if (orphans.every((m) => m.device === device)) {
              output = false;
            } else {
              output = true;
              setGroupingErrorMsgKey(
                "measurements.actions.groupingError.needSameDevice"
              );
            }
          } else {
            output = true;
            setGroupingErrorMsgKey(
              "measurements.actions.groupingError.needSameCalibration"
            );
          }
        }
      }
    } else {
      if (groups.length > 1) {
        output = true;
        setGroupingErrorMsgKey(
          "measurements.actions.groupingError.justOneGroup"
        );
      }

      if (groups.length === 1 && orphans.length === 0) {
        output = true;
        setGroupingErrorMsgKey(
          "measurements.actions.groupingError.selectMeasurements"
        );
      }
    }

    return output;
  }, [measurements]);

  const deleteDisabled = useMemo(() => {
    let output = false;

    if (measurements.length === 0) {
      output = true;
    } else {
      const isGroup = measurements[0]?.isGroup;

      output = !measurements.every((m) => m.isGroup === isGroup);
    }

    return output;
  }, [measurements]);

  const exportDisabled = useMemo(() => {
    let output = true;

    if (measurements.length > 0) {
      output = false;
    }

    return output;
  }, [measurements]);

  const averagesDisabled = useMemo(() => {
    let output = false;
    if (measurements.length < 2) {
      setAverageErrorMsgKey("measurements.actions.selectAtLeastTwo");
      output = true;
    } else {
      const calibration = measurements[0]?.calibration;
      const isGroup = measurements[0]?.isGroup;

      if (!measurements.every((m) => m.calibration === calibration)) {
        setAverageErrorMsgKey("measurements.actions.average.error");
        output = true;
      } else if (!measurements.every((m) => m.isGroup === isGroup)) {
        setAverageErrorMsgKey("measurements.actions.average.grouperror");
        output = true;
      }
    }

    return output;
  }, [measurements]);

  function renderOption(option: Option) {
    if (option === "average") {
      return averagesDisabled ? (
        <div className={styles.averagesDisabled}>
          <img src={errorImage} alt="error" />
          <T id="measurements.actions.average" />
          <div className={styles.averagesError}>
            <img src={errorImage} alt="error" />
            <T id={averageErroMsgKey} />
          </div>
        </div>
      ) : (
        <T id="measurements.actions.average" />
      );
    } else if (option === "export") {
      return <T id="measurements.actions.export" />;
    } else if (option === "grouping") {
      let groups = measurements.filter((obj) => obj.isGroup === true);
      let orphans = measurements.filter((obj) => obj.isGroup === false);
      let id = "measurements.actions.createGroup";

      if (groups.length === 1 && orphans.length > 0) {
        id = "measurements.actions.addIntoGroup";
      }

      return groupingDisabled ? (
        <div className={styles.averagesDisabled}>
          {groupingErrorMsgKey ? <img src={errorImage} alt="error" /> : null}
          <T id={id} />
          {groupingErrorMsgKey ? (
            <div className={styles.averagesError}>
              <img src={errorImage} alt="error" />
              <T id={groupingErrorMsgKey ? groupingErrorMsgKey : undefined} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <T id={id} />
      );
    } else {
      return <T id="delete" />;
    }
  }

  return (
    <>
      <Dropdown
        options={[...options]}
        getKey={(o) => o}
        getDisabled={(o) =>
          (o === "average" && averagesDisabled) ||
          (o === "delete" && deleteDisabled) ||
          (o === "grouping" && groupingDisabled) ||
          (o === "export" && exportDisabled)
        }
        render={renderOption}
        onSelect={setSelected}
      >
        <>
          <ActionsIcon />
          <T id="measurements.actions" />
        </>
      </Dropdown>

      {selected === "average" ? (
        <Average />
      ) : selected === "delete" ? (
        <Delete />
      ) : selected === "export" ? (
        <Export />
      ) : selected === "grouping" ? (
        <Grouping />
      ) : null}
    </>
  );
}

export default ListActions;
